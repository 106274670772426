import { boot } from 'quasar/wrappers';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { LoadingBar, Notify } from 'quasar';
import { ErrorResponse } from 'src/classes/Common';

import { i18n } from './i18n';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
    $api: AxiosInstance;
  }
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
const api = axios.create({});

const responseHandler = (response: AxiosResponse) => {
  LoadingBar.stop();
  return response;
};

const errorHandler = (error: AxiosError) => {
  LoadingBar.stop();

  const status: number = error.response?.status || 0;
  const errorResponse = error.response?.data as ErrorResponse;

  if (status >= 400) {
    Notify.create({
      message: i18n.global.t('error.type.' + errorResponse.errorType),
      icon: 'fas fa-exclamation-circle',
      type: 'negative',
      color: 'app-blue-complementary',
      caption: i18n.global.t('error.errorCode.' + errorResponse.errorCode),
    });
  }

  return Promise.reject(error.response);
};

const requestHandler = (config: InternalAxiosRequestConfig) => {
  LoadingBar.start();
  return config;
};

api.interceptors.request.use(requestHandler);
api.interceptors.response.use(responseHandler, errorHandler);

export default boot(({ app }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});

export { api };
