export const FUNCTION_URL_REFRESH_TOKEN = '/firebase/refresh';
export const FUNCTION_URL_LOGIN = '/firebase/login';
export const FUNCTION_URL_CHANGE_PASSWORD = '/firebase/change-password';

export const SITE_VERIFY_URL =
  'https://challenges.cloudflare.com/turnstile/v0/siteverify';

export interface UserPassLoginRequest {
  username: string;
  password: string;
  turnstileToken: string;
}

export interface LoginResponse {
  displayName: string;
  idToken: string;
  email: string;
  expiresIn: string;
  localId: string;
  profilePicture: string;
  refreshToken: string;
  registered: boolean;
}

export interface RefreshTokenRequest {
  refreshToken: string;
  userId: string;
}

export interface RefreshTokenResponse {
  idToken: string;
  refreshToken: string;
}

export interface SiteVerifyResponse {
  success: string;
  'error-codes': Array<string>;
  cdata: string;
  action: string;
  hostname: string;
}

export interface SiteVerifyRequest {
  secret: string;
  response: string;
  remoteip?: string | null;
  idempotencyKey?: string | null;
}

// change-password
export interface ChangePasswordRequest {
  userId: string;
  idToken: string;
  email: string;
  oldPassword: string;
  newPassword: string;
}

export class ChangePasswordResponse {
  idToken: string;
  refreshToken: string;

  constructor(idToken: string, refreshToken: string) {
    this.idToken = idToken;
    this.refreshToken = refreshToken;
  }
}
