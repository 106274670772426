import { boot } from 'quasar/wrappers';
import { inject, InjectionKey } from 'vue';

export const storageSymbol = Symbol(
  'storageService'
) as InjectionKey<StorageService>;

interface StorageService {
  setItem<T>(key: string, value: T): Promise<string>;
  getItem<T>(key: string): Promise<T | null>;
  removeItem(key: string): Promise<void>;
}

const storageService: StorageService = {
  setItem<T>(key: string, value: T): Promise<string> {
    window.sessionStorage.setItem(key, JSON.stringify(value));
    return Promise.resolve('');
  },

  getItem<T>(key: string): Promise<T | null> {
    return Promise.resolve(
      JSON.parse(window.sessionStorage.getItem(key) || '""') as T
    );
  },

  removeItem(key: string): Promise<void> {
    return Promise.resolve(window.sessionStorage.removeItem(key));
  },
};

export default boot(async ({ app }) => {
  app.provide(storageSymbol, storageService);
});

export function useStorage(): StorageService | undefined {
  return inject(storageSymbol);
}

export { storageService };
